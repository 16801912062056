import React, { Fragment } from 'react'
import { Grid } from '@material-ui/core'
import RiskClass from '../Customized/RiskClass'
import HGStatusDots from '../HomogeneousGroup/HGStatusDots'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/styles'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'


export default function RiskDisplayPU({ groups, employeesCount, inputedEmployees }) {
    const theme = useTheme()

    const employeesMismatch = employeesCount !== inputedEmployees

    function calcTitle() {
        let title = 'Numerosità totale'
        if (employeesMismatch) {
            title = 'Verifica le numerosità nei gruppi omogenei'
        }
        return title
    }

    return <Grid container>
        <Grid item xs={12}>
            <Typography variant={'caption'} color={'textSecondary'} align='center'>PERSONALE</Typography>
        </Grid>
        <Grid item xs={12}>
            <Tooltip title={calcTitle()}>
                <Paper
                  elevation={0}
                  style={{
                      // flexGrow: 1,
                      backgroundColor: employeesMismatch ? theme.palette.error.main : 'transparent',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: 'row',
                      padding: theme.spacing(1),
                      marginBottom: theme.spacing(1)
                  }}
                >
                    <GroupRoundedIcon
                      style={{
                          fontSize: 15,
                          color: employeesMismatch ? 'white' : '#d6cfd6',
                          marginRight: theme.spacing(0.5)
                      }}/>
                    <Typography variant={'caption'}
                                style={{ color: employeesMismatch ? theme.palette.error.contrastText : '#bdb7bd' }}>
                        {employeesMismatch ? `${inputedEmployees}/${employeesCount}` : employeesCount}
                    </Typography>
                </Paper>
            </Tooltip>
        </Grid>
        <HGStatusDots groups={groups}/>
    </Grid>
}