import React, { Fragment } from 'react'
import StopRoundedIcon from '@material-ui/icons/StopRounded'
import { Box } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { formatDecimal } from '../utils'

const colors = {
    1: '#23ee23',
    2: '#7dc53b',
    3: '#efdf39',
    4: 'orange',
    5: 'red'
}

const RoundedSquare = ({ style }) => <span
  style={{ display: 'inline-block', width: 13, height: 13, borderRadius: 3, ...style }}/>

export default function ClassRating({ classValue = 0, riskPercentage = 0 }) {
    return <Tooltip title={`${formatDecimal(riskPercentage)}% = class ${classValue}`}>
        <div>
            {[1, 2, 3, 4, 5].map((n) => <RoundedSquare
              style={{
                  backgroundColor: colors[n],
                  opacity: classValue >= n ? 1 : 0.1,
                  height: n * 11 + 3,
                  marginLeft: 2
              }}/>)}
        </div>
    </Tooltip>
};
    