import React from 'react'
import { Box } from '@material-ui/core'
import { HorizontalBar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import { percentageToGreenRedRange } from '../../utils'
import Typography from '@material-ui/core/Typography'


const useStyle = makeStyles(theme => ({
    chartBox: {
        height: 120,
        padding: theme.spacing(1),
        maxWidth: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto'
    }
}))

export default function RiskClass({ style, baseRisk = 0, aggravatedUnmitigatedRiskDelta = 0, aggravatedRiskDelta = 0, initialRisk = 0, ...rest }) {

    const graphRef = React.createRef()

    function setRef(ref) {
        graphRef.current = ref
    }

    const max = 96
    const maxInitial = 24
    const classes = useStyle(rest)
    const percentBaseRisk = baseRisk / maxInitial * 100
    const percentReducedRisk = aggravatedRiskDelta / maxInitial * 100
    const percentInitialRisk = initialRisk / maxInitial * 100
    const colors = {
        border1: percentageToGreenRedRange(percentBaseRisk + percentReducedRisk, 90, 75),
        border2: percentageToGreenRedRange(percentBaseRisk + percentReducedRisk + 15, 90, 75),
        base1: percentageToGreenRedRange(percentBaseRisk + percentReducedRisk, 35, 85),
        base2: percentageToGreenRedRange(percentBaseRisk + percentReducedRisk + 15, 45, 85),
        hover1: percentageToGreenRedRange(percentBaseRisk + percentReducedRisk, 35, 85),
        hover2: percentageToGreenRedRange(percentBaseRisk + percentReducedRisk + 15, 45, 85),
        borderInitial: percentageToGreenRedRange(percentInitialRisk, 100, 80),
        baseInitial: percentageToGreenRedRange(percentInitialRisk, 45, 80, 0.7),
        hoverInitial: percentageToGreenRedRange(percentInitialRisk, 80, 95, 0.7)
    }

    const datasets = [
        {
            label: 'Rischio',
            data: [initialRisk, baseRisk],
            borderWidth: 1,
            borderColor: [colors.borderInitial, colors.border1],
            hoverBorderColor: [colors.borderInitial, colors.border1],
            backgroundColor: [colors.baseInitial, colors.base1],
            hoverBackgroundColor: [colors.hoverInitial, colors.hover1],
            stack: 'main',
            barPercentage: 0.6
        }

    ]
    if (aggravatedRiskDelta) {
        datasets.push({
            label: 'Criticità',
            data: [aggravatedUnmitigatedRiskDelta, aggravatedRiskDelta],
            borderWidth: 1,
            borderColor: [colors.border2, colors.border2],
            hoverBorderColor: [colors.border2, colors.border2],
            backgroundColor: [colors.border2, colors.base2],
            hoverBackgroundColor: [colors.border2, colors.hover2],
            stack: 'main',
            barPercentage: 0.6
        })
    }
    return <Box className={classes.chartBox}>
        <HorizontalBar
          ref={setRef}
          data={{
              labels: ['Inerente', 'Residuo'],
              datasets
          }}
          options={{
              legend: null,
              responsive: true,
              maintainAspectRatio: false,
              tooltips: {
                  position: 'nearest',
                  callbacks: {
                      label: function (tooltipItem, data) {
                          let label = data.datasets[tooltipItem.datasetIndex].label || ''
                          if (label) {
                              label += ': '
                          }
                          if (tooltipItem.xLabel === 0) {
                              label = null
                          } else {
                              label += Math.round(tooltipItem.xLabel * 100) / 100
                          }
                          return label
                      }
                  }
              },
              scales: {
                  xAxes: [{
                      stacked: true,
                      display: true,
                      ticks: {
                          display: false,
                          max: maxInitial,
                          min: 0,
                          stepSize: maxInitial / 5
                      }
                  }],
                  yAxes: [{
                      beginAtZero: true
                  }]
              },
              layout: {
                  padding: {
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 5
                  }
              }
          }}
        />
    </Box>
}