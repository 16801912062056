import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { ArrowBackRounded } from '@material-ui/icons'
import { useLocation, useHistory } from 'react-router-dom'


export default function BackButton(props) {
    const appLocation = useLocation()
    const appHistory = useHistory()

    return ['/','/login'].includes(appLocation?.pathname) ? null :
      <IconButton onClick={() => appHistory.goBack()} {...props} color='inherit'>
          <ArrowBackRounded/>
      </IconButton>
}