import React from 'react'
import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { useConfirmDialog } from '../../globalState'

export default function Confirm() {
    const [isConfirmOpen, closeConfirm, onConfirm, onDeny, title, description] = useConfirmDialog(state => [
        state.isConfirmOpen,
        state.closeConfirm,
        state.onConfirm,
        state.onDeny,
        state.title,
        state.description
    ])

    function handleConfirmed() {
        closeConfirm()
        onConfirm()
    }

    function handleDenied() {
        closeConfirm()
        onDeny && onDeny()
    }

    return <Dialog
      open={isConfirmOpen}
      onClose={closeConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleConfirmed} color="primary" variant={'contained'}>
                Ok
            </Button>
            <Button onClick={handleDenied} color="primary" autoFocus>
                Annulla
            </Button>
        </DialogActions>
    </Dialog>
}