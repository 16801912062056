import React, { Fragment } from 'react'
import { Box, Typography, Grid, LinearProgress } from '@material-ui/core'
import ActivitiesForm from './ActivitiesForm'
import { Doughnut, Radar } from 'react-chartjs-2'
import { doughnutColors, formatAreasForDoughnut, formatDecimal } from '../../utils'
import MitigationForm from './MitigationForm'
import { makeStyles } from '@material-ui/core/styles'
import { AddButton } from '../Customized'
import Paper from '@material-ui/core/Paper'
import ColoredRibbon from '../ColoredRibbon'


// function styledCurrentSlice(doughnutData, currentIndex, activeColor, baseColor) {
//     const cloned = _.cloneDeep(doughnutData)
//     cloned.datasets[0].backgroundColor = Array(cloned.datasets[0].data.length).fill(baseColor)
//     cloned.datasets[0].backgroundColor[currentIndex] = activeColor
//     return cloned
// }

const useStyles = makeStyles(theme => ({
    radar: {
        minHeight: 300,
        marginTop: theme.spacing(8)
    },
    activityBox: {
        margin: theme.spacing(1)
    },
    hideOverflow: {
        position: 'relative',
        overflow: 'hidden'
    },
    customPadding: {
        marginTop: theme.spacing(0.5),
        padding: theme.spacing(1)
    },
    sticky: {
        [theme.breakpoints.up('md')]: {
            position: 'sticky',
            top: 60
        },
        height: 400,
        marginBottom: theme.spacing(8)
    }
}))

export default function DetailHG({ data = {}, updateHomogeneousGroupActivities, ...rest }) { //TODO replace blendColors with hue rotate green --> red
    const risk = data.risk || {}
    const classes = useStyles()
    const mitigation = data.mitigation || {}
    const { data: genericGraphData, total } = formatAreasForDoughnut(risk.proximityCategories)
    // const theme = useTheme()
    return <Box>
        {!risk?.proximityCategories?.length ? null :

          <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} className={classes.sticky}>

                  <Paper className={classes.customPadding}>
                      <Grid item xs={12}>
                          <Typography gutterBottom variant={'h5'}>Tempo assegnato</Typography>
                          {total > 100 ? <p>Error</p> : null}
                          {total < 100 ?
                            <Typography variant={'overline'} color={'error'}>Controlla il totale</Typography> : null}
                          <Doughnut
                            legend={null}
                            options={{ maintainAspectRatio: true }}
                            data={genericGraphData}
                          />
                      </Grid>
                  </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={8} container spacing={1}>
                  {risk?.proximityCategories.map((area, currentIndex) =>
                    <Grid key={currentIndex} xs={12} className={classes.activityBox}>
                        <Paper className={classes.hideOverflow}>
                            <ColoredRibbon color={doughnutColors[currentIndex]}/>
                            <Typography variant="h6" component="h3">
                                {area.name || 'Attività a rischio P=' + area.weight}
                            </Typography>
                            <ActivitiesForm riskGroupIndex={currentIndex} activities={area.activities}
                                            onSubmit={updateHomogeneousGroupActivities}/>
                        </Paper>
                    </Grid>
                  )}
              </Grid>
          </Grid>
        }

    </Box>

}