import React, { Fragment } from 'react'
import { Card, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@material-ui/core'
import RiskRating from '../RiskRating'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import RisksBarChart from '../Customized/RiskComparisonGraph'
import { Doughnut } from 'react-chartjs-2'
import { formatAreasForDoughnut } from '../../utils'
import GroupAddRoundedIcon from '@material-ui/icons/GroupRounded'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import LocalHospitalIcon from '@material-ui/icons/LocalHospitalRounded'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterRounded'
import WarningRounded from '@material-ui/icons/WarningRounded'
import AccessibilityNewRoundedIcon from '@material-ui/icons/AccessibilityNewRounded'
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'

const criticalitiesIcons = {
    health: LocalHospitalIcon,
    service: SettingsApplicationsRoundedIcon,
    business: BusinessCenterIcon
}

export default function CardHG({ image, name, category, address, risk, handleMoreClick, ownerProductionUnit, employeesCount, criticalities, ...rest }) {
    const theme = useTheme(rest)
    const { data: genericGraphData, total } = formatAreasForDoughnut(risk?.proximityCategories)
    const hasCriticalities = !!criticalities && criticalities !== 'none'
    const CriticalityIcon = hasCriticalities && criticalitiesIcons[criticalities]
    return <Card {...rest}>
        <CardHeader
          action={<IconButton aria-label="settings" onClick={handleMoreClick}>
              <MoreVertIcon/>
          </IconButton>}
          title={<Typography variant={'h5'}>{name}</Typography>}
          subheader={address}
        />
        <CardContent>
            <Typography color="textSecondary">{category}</Typography>
            <Typography variant="body2" component="p">
                {address}
            </Typography>
            <Grid container spacing={1} style={{ marginBottom: theme.spacing(4) }}>
                <Grid item xs={12} sm={6} style={{ height: 110, maxHeight: 110 }}>
                    <Typography variant={'caption'}>% ATTIVITÀ</Typography>
                    <div style={{ height: 80 }}>
                        <Doughnut
                          legend={null}
                          options={{ maintainAspectRatio: false, animation: { duration: 0 } }}
                          data={genericGraphData}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} container>

                    <Grid item xs={12} style={{ height: 70, maxHeight: 70 }}>
                        <Typography variant={'caption'}>NUMEROSITÀ</Typography>

                        <Paper style={{
                            backgroundColor: '#f2f2f2',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            padding: theme.spacing(0.5)
                        }}
                               elevation={0}>
                            <Typography variant={'h3'}>{employeesCount}</Typography>
                        </Paper>
                        {/*<Grid item alignContent={'center'}>*/}
                        {/*</Grid>*/}
                        {/*<Grid item>*/}

                        {/*</Grid>*/}
                    </Grid>
                      <Grid item xs={12} style={{ height: 70, maxHeight: 70 }}>
                          
                          {hasCriticalities &&  <Fragment>
                              <Typography variant={'caption'}>CRITICITÀ</Typography>

                              <Paper style={{
                                  backgroundColor: hasCriticalities ? '#f2dac4' : '#f2f2f2',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  padding: theme.spacing(1)
                              }}
                                     elevation={0}>
                                  {
                                      hasCriticalities &&
                                      // <Tooltip title={`Gruppo critico per catena del valore: ${criticalities}`}>
                                          <Fragment>
                                              <Typography color={'warning'} variant={'caption'}>{criticalities}</Typography>
                                              <CriticalityIcon style={{ color: theme.palette.warning.dark }}/>
                                          </Fragment>
                                      // </Tooltip>
                                  }
                              </Paper>
                          </Fragment>}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <RisksBarChart baseRisk={risk?.totalRisk} reducedRisk={risk?.mitigatedRisk}/>
                </Grid>
            </Grid>
            {/*<Typography variant={'caption'}>Rischio totale</Typography>*/}
            {/*<RiskRating value={risk?.totalRisk}/>*/}
            {/*<Typography variant={'caption'}>Rischio ridotto</Typography>*/}
            {/*<RiskRating value={risk?.mitigatedRisk}/>*/}
        </CardContent>
    </Card>
}