import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'

import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'

export default function DashboardValue({ title = '', subtitle, tooltipText, value, valueColor, trendIndicatorComponent }) {
    const Icon = trendIndicatorComponent
    const TooltipOrNothing = tooltipText ? Tooltip : Fragment
    return <TooltipOrNothing title={tooltipText}>
        <Box container spacing={3} alignContent={'center'} justify={'center'}>

            <Typography variant={'h2'}>{title}</Typography>
            {subtitle ? <Typography variant={'caption'}>{subtitle}</Typography> :
              <Typography variant={'caption'} style={{ color: 'transparent' }}>{'_'}</Typography>
              //yeah i just did that. Seems like material ui removes empty text, but i need it to take space
            }

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {value && <Typography variant={'h1'} style={{ color: valueColor }}>{value}</Typography>}
                {trendIndicatorComponent && <Icon/>}
            </div>
        </Box>
    </TooltipOrNothing>
}
