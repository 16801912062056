import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Dialog, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2)
    },
    addButton: {
        color: theme.palette.primary.dark
    }
}))

export function CustomDialog(props) {
    const classes = useStyles(props)
    return <Dialog{...props} className={classes.dialog}/>
}

export function AddButton(props) {
    const classes = useStyles(props)
    return <Button {...props}>
        <AddIcon className={classes.addButton}/>
    </Button>
}