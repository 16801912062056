import React, { Fragment } from 'react'
import { Container } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import DetailHG from '../Components/HomogeneousGroup/DetailHG'
import { useMutation, useQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
    HOMOGENEOUS_GROUP_DETAILS_QUERY, UPDATE_HOMOGENEOUS_GROUP_ACTIVITIES
} from '../Components/HomogeneousGroup/GraphQL_queries'
import { notifyFailure } from '../utils'
import PageTitle from '../Components/Customized/PageTitle'
import { PRODUCTION_UNITS_QUERY } from '../Components/ProductionUnit/GraphQL_queries'

export default function HomogeneousGroupDetailScreen() {
    const { groupID } = useParams()
    const { loading, data } = useQuery(HOMOGENEOUS_GROUP_DETAILS_QUERY, { variables: { idList: [groupID] } })
    const [_updateHomogeneousGroupActivities] = useMutation(UPDATE_HOMOGENEOUS_GROUP_ACTIVITIES, {
        refetchQueries: [{
            query: HOMOGENEOUS_GROUP_DETAILS_QUERY
        }]
    })

    function updateHomogeneousGroupActivities({ activities = [], riskGroupIndex }) {
        const prunedActivities = activities.map(activity => _.pick(activity, ['description', 'timePercentage', 'aggregation']))
        const payload = { groupId: groupID, activities: prunedActivities, riskGroupIndex }
        return _updateHomogeneousGroupActivities({ variables: payload }).catch(notifyFailure)
    }


    return <Container>
        {loading ? <CircularProgress/> :
          <Fragment>
              <PageTitle>{data?.homogeneousGroups?.[0].name}</PageTitle>
              <DetailHG
                data={data?.homogeneousGroups?.[0]}
                updateHomogeneousGroupActivities={updateHomogeneousGroupActivities}
              />
          </Fragment>
        }
    </Container>
}