import _ from 'lodash'
import { toast } from 'react-toastify'

toast.configure()

export function notifyFailure(message = 'Qualcosa è andato storto') {
    toast.error(message)
    console.error(message)
}

export function notifySuccess() {
    toast.success('Operazione effettuata', { autoClose: 2000 })
}

export const percentageToGreenRedRange = (hue, saturation = 85, brightness = 80, alpha = 1) => {
    const h = 120 - hue * 1.2
    const s = saturation / 100
    const v = brightness / 100
    const f = (n, k = (n + h / 60) % 6) => v - v * s * Math.max(Math.min(k, 4 - k, 1), 0)
    const res = [f(5) * 255, f(3) * 255, f(1) * 255]
    return `rgba(${res[0]},${res[1]},${res[2]}, ${alpha})`
}

/* eslint-disable */
export const blendColors = (c0, c1, p) => { //https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
    let f = parseInt(c0.slice(1), 16), t = parseInt(c1.slice(1), 16), R1 = f >> 16, G1 = f >> 8 & 0x00FF,
      B1 = f & 0x0000FF, R2 = t >> 16, G2 = t >> 8 & 0x00FF, B2 = t & 0x0000FF
    return '#' + (0x1000000 + (Math.round((R2 - R1) * p) + R1) * 0x10000 + (Math.round((G2 - G1) * p) + G1) * 0x100 + (Math.round((B2 - B1) * p) + B1)).toString(16).slice(1)
}

export const formatDecimal = (someNumber) => Number.isNaN(someNumber) ? '' : _.round(someNumber, 2)


export const doughnutColors = ['green', 'yellow', 'orange', 'red']

/* eslint-disable */
export function formatAreasForDoughnut(allAreas) {
    const data = {
        datasets: [{ data: [], backgroundColor: doughnutColors, labels: [] }],
        labels: [],
        text: '0%'
    }
    if (!allAreas) return data
    let total = 0
    const defaultAreasNames = ['R. minimo', 'R. basso', 'R. medio', 'R. elevato']
    for (let i = 0; i < allAreas.length; i++) {
        const area = allAreas[i]
        data.datasets[0].data.push(area.activities.reduce((acc, curr) => {
            total += curr.timePercentage
            return acc + curr.timePercentage
        }, 0)) //somma timePercentage
        data.labels.push(area.name || defaultAreasNames[i] || 'Area senza nome')
    }
    data.text = formatDecimal(total) + '%'
    return { data, total }
}

export function countermeasuresToRadarDatatype(mitigationActivities) {
    console.log('calculating data for radar graph', { mitigationActivities })
    return {
        labels: mitigationActivities.map(c => c.name),
        datasets: [
            {
                data: mitigationActivities.map(c => c.practicalExecution),
                label: 'Estensione',
                borderColor: 'rgb(7,199,0)',
                backgroundColor: 'rgba(7,199,0,0.43)'
            },
            {
                data: mitigationActivities.map(c => c.practicalEfficacy * c.practicalExecution / 100),
                borderColor: 'rgb(46,52,255)',
                label: 'Efficacia (relativa)',
                backgroundColor: 'rgba(46,52,255, 0.64)'
            }]
    }
}

export function countermeasuresToRadarDatatype2(mitigationActivities) {
    const colors = [
        'rgba(218,0,0, 0.6)',
        'rgba(216,107,0, 0.6)',
        'rgba(31,29,227, 0.6)',
        'rgba(116,190,0, 0.6)',
        'rgba(0,140,219, 0.6)',
        'rgba(214,0,213, 0.6)',
        'rgba(0,203,37, 0.6)',
        'rgba(204,199,0, 0.6)'
    ]
    const datasets = []
    const groups = _.groupBy(mitigationActivities, ma => ma.group)
    let counter = 0
    const labels = []
    Object.values(groups).map((actGroup, index, groupValues) => {

        const groupColor = colors[index % (colors.length - 1)]
        const groupExtData = new Array(mitigationActivities.length).fill(0)
        const groupEffData = new Array(mitigationActivities.length).fill(0)
        if (index > 0) {
            const previousGroup = groupValues[index - 1]
            const lastElem = previousGroup[previousGroup.length - 1]
            groupEffData[counter - 1] = lastElem.practicalExecution || 0
            groupExtData[counter - 1] = (lastElem.practicalExecution * lastElem.practicalEfficacy / 100) || 0
        }
        if (index === groupValues.length - 1) {
            const firstGroup = groupValues[0]
            const firstElem = firstGroup[0]

            groupEffData[0] = firstElem.practicalExecution || 0
            groupExtData[0] = (firstElem.practicalExecution * firstElem.practicalEfficacy / 100) || 0
        }
        actGroup.forEach(activity => {
            labels.push(activity.name)
            groupExtData[counter] = activity.practicalExecution || 0
            groupEffData[counter] = (activity.practicalExecution * activity.practicalEfficacy / 100) || 0
            counter++ // track activity # regardless of groups
        })
        datasets.push({
            data: groupExtData,
            label: 'Attuazione',
            borderColor: 'rgb(138,135,140)',
            backgroundColor: 'rgba(126,122,126,0.43)'
        })
        datasets.push({
            data: groupEffData,
            label: 'Efficacia (relativa)',
            borderColor: groupColor,
            backgroundColor: groupColor
        })
    })
    console.log('calculated datasets')
    return {
        labels: labels,
        datasets: datasets
        // [
        //   {
        //       data: mitigationActivities.map(c => c.practicalExecution),
        //       label: 'Estensione',
        //       borderColor: 'rgb(7,199,0)',
        //       backgroundColor: 'rgba(7,199,0,0.43)'
        //   },
        //   {
        //       data: mitigationActivities.map(c => c.practicalEfficacy * c.practicalExecution / 100),
        //       borderColor: 'rgb(46,52,255)',
        //       label: 'Efficacia (relativa)',
        //       backgroundColor: 'rgba(46,52,255, 0.64)'
        //   }]
    }
}


export function defaultMutationUpdateCallback({ cache, mutatedData, modelName, modelCollectionName, gqlFragment, isDelete }) {
    let model
    if (isDelete) {
        model = { id: mutatedData['deletedId'] } // make sure database is returning this after deletions
    } else {
        model = mutatedData[modelName]
    }
    cache.modify({
        fields: {
            [modelCollectionName]: function (modelCollection = []) {
                if (isDelete) {
                    return modelCollection.filter(m => m.__ref !== model.id) // graphql is fucking incoherent
                } else {
                    // const { homogeneousGroup } = upsertHomogeneousGroup
                    const foundIndex = _.findIndex(modelCollection, p => p.__ref === model.id)
                    if (foundIndex >= 0) { // so found it
                        return [
                            ...modelCollection.slice(0, foundIndex),
                            { ...modelCollection[foundIndex], ...model },
                            ...modelCollection.slice(foundIndex + 1, modelCollection.length)
                        ]
                    } else {
                        const newModelRef = cache.writeFragment({
                            id: model.id,
                            data: model,
                            fragment: gqlFragment
                        })
                        return [...modelCollection, newModelRef]
                    }
                }

            }
        }
    })
}