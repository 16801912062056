import { gql } from '@apollo/client'

export const PRODUCTION_UNITS_QUERY = gql`
    query getProductionUnits($unitId: ID) {
        productionUnits(id: $unitId) {
            industryCode,
            name,
            id,
            address,
            province,
            category,
            k1,
            k3,
            risk {
                initial,
                raw,
                final,
                initialClass,
                finalClass,
                finalPercentage,
                initialPercentage
            }
            employeesCount
            homogeneousGroups {
                id,
                name,
                risk {
                    totalRisk
                    mitigatedRisk
                },
                criticalities,
                employeesCount
            },
            mitigation {
                mitigationActivities {
                    name
                    group
                    practicalEfficacy
                    practicalExecution
                    theoreticalMax
                    theoreticalMin
                    k
                }
                totalMitigation
            }
        }
    }
`
export const INSERT_PRODUCTION_UNIT = gql`
    mutation insertProductionUnit($name: String, $industryCode: String, $employeesCount: Int, $address: String, $province: String) {
        insertProductionUnit(productionUnitData: {name: $name, industryCode: $industryCode, employeesCount: $employeesCount, address: $address, province: $province}) {
            productionUnit {
                id
                name
                address
                province
                category
                industryCode,
                employeesCount,
                homogeneousGroups{
                    id,
                    employeesCount
                }
            }
        }
    }
`

export const UPDATE_PRODUCTION_UNIT = gql`
    mutation updateProductionUnit($id: ID, $name: String, $industryCode: String, $employeesCount: Int, $address: String, $province: String) {
        updateProductionUnit(productionUnitData: {id: $id, name: $name, industryCode: $industryCode, employeesCount: $employeesCount, address: $address, province: $province}) {
            productionUnit {
                id
                name
                address
                province
                category
                industryCode,
                employeesCount,
                homogeneousGroups{
                    id,
                    employeesCount
                },
                risk {
                    initial
                    raw
                    final
                    initialClass
                    finalClass,
                    finalPercentage,
                    initialPercentage
                }
            }
        }
    }
`


export const UPDATE_PRODUCTION_UNIT_MITIGATION_ACTIVITIES = gql`
    mutation editProductionUnitMitigationActivities($productionUnitId: ID!, $mitigationActivitiesData: [MitigationActivityFieldInput]!){
        editProductionUnitMitigationActivities(productionUnitId: $productionUnitId, mitigationActivitiesData: $mitigationActivitiesData) {
            productionUnit {
                id,
                mitigation {
                    mitigationActivities {
                        group,
                        name
                        practicalEfficacy
                        practicalExecution
                        theoreticalMax
                        theoreticalMin
                        k
                    }
                    totalMitigation
                },
                homogeneousGroups {
                    id,
                    name,
                    risk {
                        totalRisk
                        mitigatedRisk
                    },
                    criticalities,
                    employeesCount
                },
                risk {
                    initial,
                    raw,
                    final,
                    initialClass,
                    finalClass,
                    finalPercentage,
                    initialPercentage
                }
            }
        }
    }
`

export const DELETE_PRODUCTION_UNIT = gql`
    mutation deleteProductionUnit($id: ID) {
        deleteProductionUnit(productionUnitData: {id: $id}) {
            deletedId
        }
    }
`
