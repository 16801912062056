import create from 'zustand'


const persist = (name, config) => (set, get, api) => { //writing to localstore is slow, so persist only if necessary
    const state = config(
      (payloadFn) => {
          const newState = { ...get(), ...payloadFn() }
          set(newState)
          localStorage.setItem(name, JSON.stringify(newState))
      },
      get,
      api
    )

    return {
        ...state,
        ...(JSON.parse(localStorage.getItem(name)))
    }
}

export const [useLoginStore] = create(
  persist('loginStore', (set, get) => ({
      isLoggedIn: false,
      setLoggedIn: () => set(state => ({ isLoggedIn: true })),
      setLoggedOut: () => set(state => ({ isLoggedIn: false }))
  }))
)


const confirmDialogInitialState = {
    isConfirmOpen: false,
    title: '',
    description: '',
    onConfirm: () => {
    },
    onDeny: () => {
    }
}

export const [useConfirmDialog] = create(set => ({
      ...confirmDialogInitialState,
      openConfirm: ({ title, description, onConfirm = confirmDialogInitialState.onConfirm, onDeny = confirmDialogInitialState.onDeny }) => set({
          isConfirmOpen: true,
          title,
          description,
          onConfirm,
          onDeny
      }),
      closeConfirm: () => set(confirmDialogInitialState)
  })
)