import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'


const useStyle = makeStyles(theme => ({
    title: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    }
}))

export default function Title(props) {
    const classes = useStyle()
    return <Typography variant={'h1'} className={classes.title} {...props}/>
}