import React from 'react'
import Typography from '@material-ui/core/Typography'


const styles = {
    link: {
        textDecoration: 'none',
        width: '100%'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    defaultImgStyle: {
        width: 130, height: 80, objectFit: 'contain'
    }
}

export default function LinkImage({ href, imgSrc, altText, caption, imgStyle = styles.defaultImgStyle, containerStyle }) {
    return <a href={href} target={'_blank'} rel={'noopener noreferrer'} style={styles.link}>
        <div style={{...styles.container, ...containerStyle}}>
            <img src={imgSrc} alt={altText || caption} style={imgStyle}/>
            <Typography style={caption ? { flex: 1, textAlign: 'right' } : { color: 'transparent', flex: 1 }}
                        variant={'caption'}>{caption || '_'}</Typography>
        </div>
    </a>
}