import { Box, Grid, Typography, Paper } from '@material-ui/core'
import React from 'react'
import HomogeneousGroupsSection from '../../Screens/HomogeneousGroupsSection'
import { useHistory } from 'react-router-dom'
import MitigationForm from '../HomogeneousGroup/MitigationForm'
import { Radar } from 'react-chartjs-2'
import { countermeasuresToRadarDatatype } from '../../utils'
import hash from 'object-hash'
import DashboardActions from '../../Screens/DashboardActions'


export default function DetailPU({ data = {}, updateHomogeneousRemediationActivities }) {
    const appHistory = useHistory()

    function handleSubmit(values) {
        return updateHomogeneousRemediationActivities({ ...values, productionUnitId: data.id })
    }

    const radarData = React.useCallback(() => {
        return data.mitigation.mitigationActivities ? countermeasuresToRadarDatatype(data.mitigation.mitigationActivities) : {}
    }, [hash(data.mitigation.mitigationActivities)])

    const insertedEmployees = data?.homogeneousGroups.reduce((sum, hg) => {
        sum += hg.employeesCount
        return sum
    }, 0)

    return <Grid container spacing={4}>
        <Grid item xs={12}>
            {/*<img src={data.image} alt=""/>*/}
            {/*<Typography variant="h5" component="h2">*/}
            {/*    */}
            {/*</Typography>*/}
            <Typography color="textSecondary">{data.category}</Typography>
            <Typography variant="body2">
                {data.address}
            </Typography>
            <Typography variant="body2">
                Codice Ateco: {data.industryCode}
            </Typography>
            <div>
                <Typography variant="body2" component="span">
                    Numerosità totale:&nbsp;
                </Typography><Typography key={'insertedEmployees' + insertedEmployees} variant="body2" component="span"
                                         color={insertedEmployees != data.employeesCount ? 'error' : 'textPrimary'}>
                {insertedEmployees}/{data.employeesCount}
            </Typography>
            </div>            
            <DashboardActions province={data.province} unitID={data.id}/>

        </Grid>
        <Grid item xs={12} container spacing={6}>
            <Grid item xs={12} sm={12}>
                <HomogeneousGroupsSection productionUnitId={data.id} groupsIDs={data.homogeneousGroups}/>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant={'h4'}>Attività correttive e preventive</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={5}>
                                <Box style={{ minHeight: 400 }}>
                                    <Radar
                                      data={radarData}
                                      legend={null}
                                      options={{
                                          maintainAspectRatio: false,
                                          responsive: true,
                                          scale: {
                                              ticks: {
                                                  beginAtZero: true,
                                                  max: 100,
                                                  min: 0,
                                                  stepSize: 20
                                              },
                                              pointLabels: {
                                                  callback: function (label, index, labels) {
                                                      if (/\s/.test(label)) {
                                                          return label.split(' ')
                                                      } else {
                                                          return label
                                                      }
                                                  }
                                              }
                                          }
                                      }}

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <MitigationForm
                                  onSubmit={handleSubmit}
                                  mitigationActivities={data.mitigation.mitigationActivities}
                                />
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </Grid>


    </Grid>
}