import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import axios from 'axios'
import App from './App'
import { notifyFailure } from './utils'
import * as serviceWorker from './serviceWorker'
import './chartsGlobalConfig'
import { InMemoryCache, ApolloClient, HttpLink, split, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import Cookies from 'js-cookie'

import { getMainDefinition } from '@apollo/client/utilities'

import { WebSocketLink } from '@apollo/client/link/ws'

// This url is replaced by CI/CD in staging/production environment
export const DO_NOT_EDIT_THIS_URL_IS_REPLACED_IN_BUILD_PHASE_SERVER_BASE_URL = 'http://localhost:5000'

const httpLink = new HttpLink({
    uri: DO_NOT_EDIT_THIS_URL_IS_REPLACED_IN_BUILD_PHASE_SERVER_BASE_URL + '/graphql',
    cache: new InMemoryCache({ addTypename: false }),
    credentials: 'include'
})


const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        notifyFailure('Qualcosa è andato storto')
        graphQLErrors.map(({ message, locations, path }) => {
              console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
          }
        )
    }

    if (networkError) notifyFailure(networkError)
})

//
// const wsLink = new WebSocketLink({
//     uri: `ws://localhost:5000/subscriptions`,
//     options: {
//         reconnect: true
//     }
// })
//
//
// const splitLink = split(
//   ({ query }) => {
//       const definition = getMainDefinition(query)
//       return (
//         definition.kind === 'OperationDefinition' &&
//         definition.operation === 'subscription'
//       )
//   },
//   wsLink,
//   httpLink
// )

export const client = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    // uri: 'http://localhost:5000/graphql',
    cache: new InMemoryCache({
        addTypename: false,
        dataIdFromObject: o => o.id
    })
    // credentials: 'include'
})


axios.defaults.withCredentials = true
axios.defaults.headers['Content-Type'] = 'application/json'
/* grab the csrfToken from backend to use in future requests */
export let csrfToken = ''
axios.get(DO_NOT_EDIT_THIS_URL_IS_REPLACED_IN_BUILD_PHASE_SERVER_BASE_URL + '/login', { data: null }).then(function (resp) {
    csrfToken = resp.data['response']['csrf_token']
})
axios.interceptors.request.use(function (config) {
    let cookies = Cookies.get()
    console.log(JSON.stringify({ cookies }))
    if (['post', 'delete', 'patch', 'put'].includes(config['method'])) {
        if (csrfToken !== '') {
            config.headers['X-CSRF-Token'] = csrfToken
        }
    }
    return config
}, function (error) {
    if (error.status === 401) {
        notifyFailure("Operazione non consentita: effettua nuovamente il login se pensi si tratti di un errore")
        window.location.pathname = '/login'
    } else {
        notifyFailure('Il server non è riuscito a gestire l\'ultima operazione')
    }
    // Do something with request error
})


ReactDOM.render(
  <React.StrictMode>
      <App/>
  </React.StrictMode>,
  document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
