import React from 'react'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TrackedIndexSummary } from './PandemicStatusScreen'
import GrowthIndicator from '../Components/Customized/GrowthIndicator'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import DashboardValue from '../Components/DashboardValue'
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded'


const useStyles = makeStyles((theme) => ({
    fakeLink: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: 4,
        flex: 1,
        height: 70,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.contrastText
    },
    fakeLinkActive: {
        backgroundColor: theme.palette.primary.main
    },
    focusVisible: {}
}))

const MockButton = (props) => {
    const classes = useStyles()

    return <a
      style={{ textDecoration: 'none' }}
      target={'_blank'}
      download
      href={props.target}
    >
        <div className={`${classes.fakeLink} ${props.target ? classes.fakeLinkActive : ''}`}>
            <Typography variant={'caption'}>
                {props.label}
            </Typography>
        </div>
    </a>
}

export default function DashboardActions({ province, unitID }) {
    const classes = useStyles()

    const appHistory = useHistory()
    const provincesLazio = ['RM', 'RI', 'LT', 'FR', 'VT']
    const region = provincesLazio.includes(province?.toUpperCase()) ? 'LAZIO' : 'PIEMONTE'

    return <Container style={{ paddingTop: 40 }}>
        <Paper>
            <Grid container spacing={2} style={{ padding: 10, marginBottom: 70 }}>
                <Grid container item xs={12} style={{ marginBottom: 40 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <TrackedIndexSummary region={region}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <GrowthIndicator/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardValue
                          title={'Rispetto distanze'}
                          subtitle={'rispetto alla settimana precedente'}
                          tooltipText={'Report da applicazioni installate'}
                          value={'60%'}
                          valueColor={'#1da5f5'}
                          trendIndicatorComponent={() => <ArrowDownwardRounded style={{ color: '#00d31d' }}/>}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Button variant={'contained'}
                                onClick={() => appHistory.push(`/productionUnits/${unitID}/pandemicStatus`)}>
                            <Typography variant={'caption'}>Situazione pandemica</Typography>
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={6} md={3}>
                    <MockButton
                      label={'Direttive'}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <MockButton label={'Formazione e Comunicazione'}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <MockButton
                      label={'Checklist operative'}
                      target={'https://drive.google.com/uc?export=download&id=1KcKbA6g9Wa_dJIB8rBPEjOpih-t_3bXR'}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <MockButton label={'Best practices'}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <MockButton
                      label={'Gestione eventi e piani di emergenza'}
                      target={'https://drive.google.com/drive/folders/16ZOSQAafVQF2BhX8pB0y9NJaCMgcUlhk?usp=sharing'}
                    />
                    
                </Grid>
                <Grid item xs={6} md={3}>
                    <MockButton
                      label={'Gestione Vaccinati'}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <MockButton label={'Report autogenerati'}/>
                </Grid>
            </Grid>
        </Paper>
    </Container>
}