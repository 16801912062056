import React from 'react'


export default function MockImageContainer({width='100%', height='auto', src}) {


    return <img src={src} alt="" style={{width, height, contentFit: 'contain'}}/>






}