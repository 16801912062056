import React from 'react'
import { Box, CircularProgress, Container, Grid, Typography } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { PRODUCTION_UNITS_QUERY } from '../Components/ProductionUnit/GraphQL_queries'
import { useParams } from 'react-router-dom'
import rtValueGraphSrc from '../assets/images/rtValueGraphSrc.png'
import mtgMockSrc from '../assets/images/mtg_interaction_mock.png'
import { Doughnut } from 'react-chartjs-2'
import referenceEpicentroSrc from '../assets/images/reference_epicentro_logo.png'
import referenceGimbeLogoSrc from '../assets/images/reference_gimbe_logo.png'
import referenceINFNSrc from '../assets/images/reference_infn_logo.png'
import referenceProtezCivilSrc from '../assets/images/reference_protezione_civile_logo.png'
import referenceSSNSrc from '../assets/images/reference_ssn_logo.png'
import { formatDecimal } from '../utils'
import GrowthIndicator from '../Components/Customized/GrowthIndicator'
import DashboardValue from '../Components/DashboardValue'
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded'
import LinkImage from '../Components/Customized/LinkImage'
import MockImageContainer from '../Components/Fake/MockImageContainer'

//commentbuild ci

function GraphMock({ src }) {
    return <img src={src} alt=""
                style={{ marginTop: 40, width: 'auto', height: 480, objectFit: 'contain', backgroundColor: 'white' }}/>
}

const data = {
    datasets: [{ data: [40, 60], backgroundColor: ['#f0ce28', 'green'], labels: [] }],
    labels: ['SOTTO soglia', 'sopra soglia'],
    text: ''
}

function MTGInteractionSummary() {
    return <Box>
        <Typography variant={'h2'}>Tasso di interazione sotto soglia di sicurezza medio 14gg</Typography>
        <Box style={{ display: 'flex', justifyContent: 'center', position: 'relative', alignItems: 'center' }}>

            <img src={mtgMockSrc} alt=""
                 style={{ position: 'absolute', width: 120, height: 120, zIndex: -1, objectFit: 'contain' }}/>
            <Doughnut
              legend={null}
              options={{ maintainAspectRatio: true }}
              data={data}
            />
        </Box>
    </Box>

}


const logoStyles = {
    logo: {
        width: 130, height: 80, objectFit: 'contain'
    },
    borderBottom: {
        flex: 1,
        borderBottom: '1px solid rgba(0,0,0,0.2)'
    }
}

//commento da cancellare

function ReferencesAndAdditionalInfo() {
    return <Grid container style={{ width: '100%', maxWidth: 680, margin: '0 auto' }}>
        <Grid item xs={12}>
            <Typography style={{ width: '100%', marginBottom: 40 }} variant={'h3'}>FONTI DATI E ULTERIORI
                INFORMAZIONI</Typography>
        </Grid>
        <Grid item xs={12}>
            <LinkImage
              containerStyle={logoStyles.borderBottom}
              imgSrc={referenceINFNSrc}
              caption={'Indicatori monitorati - INFN, gruppo COVIDSTAT'}
              altText={'INFN - Istituto Nazionale di Fisica Nucleare'}
              href={'https://covid19.infn.it/sommario/'}
            />
            <LinkImage
              containerStyle={logoStyles.borderBottom}
              imgSrc={referenceINFNSrc}
              caption={'Indicatore Rt - INFN, gruppo COVIDSTAT'}
              altText={'INFN - Istituto Nazionale di Fisica Nucleare'}
              href={'https://covid19.infn.it/sommario/rt.html'}
            />
            <LinkImage
              containerStyle={logoStyles.borderBottom}
              imgSrc={referenceEpicentroSrc}
              caption={'Dashboard EpiCentro dedicata al COVID-19'}
              altText={'EpiCentro - Portale di epidemiologia per gli operatori sanitari'}
              href={'https://www.epicentro.iss.it/coronavirus/sars-cov-2-dashboard'}
            />
            <LinkImage
              containerStyle={logoStyles.borderBottom}
              imgSrc={referenceGimbeLogoSrc}
              caption={'Dashboard GIMBE dedicata al COVID-19'}
              altText={'GIMBE - Evidence for health'}
              href={'https://coronavirus.gimbe.org/'}
            />
            <LinkImage
              containerStyle={logoStyles.borderBottom}
              imgSrc={referenceProtezCivilSrc}
              caption={'Protezione Civile Nazionale - provvedimenti'}
              altText={'Protezione Civile Nazionale'}
              href={'http://www.protezionecivile.gov.it/attivita-rischi/rischio-sanitario/emergenze/coronavirus/normativa-emergenza-coronavirus/'}
            />
            <LinkImage
              imgSrc={referenceSSNSrc}
              caption={'Report settimanali'}
              altText={'SSN - Servizio Sanitario Nazionale'}
              href={null}
            />

        </Grid>
    </Grid>

}


export function TrackedIndexSummary({ region }) {
    let value = 'N/A'
    let min = 0
    let max = 2
    if (region.toUpperCase() === 'LAZIO') {
        value = 0.79
        min = 0.79
        max = 0.80
    } else {
        value = 0.82
        min = 0.84
        max = 0.81
    }
    return <DashboardValue
      title={'RT regionale 14gg'}
      subtitle={`intervallo di confidenza ${formatDecimal(min)} < Rt < ${formatDecimal(max)}`}
      tooltipText={'Fonte dati: Ministero della Salute, Istituto Superiore di Sanità Cabina di Regia ai sensi del DM Salute 30 aprile 2020'}
      value={value}
      valueColor={'#1da5f5'}
    />

    //     <Typography variant={'h2'}>RT medio 14gg</Typography>
    //     <Typography variant={'caption'}>(intervallo di confidenza compreso
    //         tra {formatDecimal(min)} e {formatDecimal(max)})</Typography>
    //     <Typography variant={'h1'} style={{ marginTop: 10, color: '#1da5f5' }}>{value}</Typography>
    // </DashboardValue>
}


export default function PandemicStatusScreen() {
    const { unitID } = useParams()
    const { loading, data } = useQuery(PRODUCTION_UNITS_QUERY, { variables: { unitId: unitID } })

    const productionUnit = data?.productionUnits?.[0]
    const provincesLazio = ['RM', 'RI', 'LT', 'FR', 'VT']
    const region = provincesLazio.includes(productionUnit?.province?.toUpperCase()) ? 'LAZIO' : 'PIEMONTE'
    console.log({ region, productionUnit})
    
    return <Container style={{ paddingTop: 40 }}>
        {loading ? <CircularProgress/>
          :
          <Grid container alignItems={'center'} justify={'center'} style={{ marginBottom: 40 }}>
              <Grid item xs={12} sm={6} md={3}>
                  <TrackedIndexSummary region={region}/>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <GrowthIndicator/>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <DashboardValue
                    title={'Rispetto distanze'}
                    subtitle={'rispetto alla settimana precedente'}
                    tooltipText={'Report da applicazioni installate'}
                    value={'60%'}
                    valueColor={'#1da5f5'}
                    trendIndicatorComponent={() => <ArrowDownwardRounded style={{ color: '#00d31d' }}/>}
                  />
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*    <INFNGraphs/>*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} md={6}>*/}
              {/*    <EpiCentroMap/>*/}
              {/*</Grid>*/}

              <Grid container xs={12} style={{ marginTop: 80, marginBottom: 80 }}>
                  {/*<Grid item xs={12} md={6} style={{ backgroundColor: 'white' }}>*/}
                  {/*    <GimbeGraph/>*/}
                  {/*</Grid>*/}
                  <Grid item xs={12} style={{ backgroundColor: 'white' }}>
                      <MockImageContainer src={rtValueGraphSrc}/>
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                  <ReferencesAndAdditionalInfo/>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*    <MTGInteractionSummary/>*/}
          </Grid>
        }
    </Container>
}