import React, { Fragment } from 'react'
import './App.css'
import { AppBar, createMuiTheme, Toolbar, Typography, CssBaseline } from '@material-ui/core'
import { ApolloProvider } from '@apollo/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from 'react-router-dom'
import ProductionUnitsScreen from './Screens/ProductionUnitsScreen'
import ProductionUnitDetailScreen from './Screens/ProductionUnitDetailScreen'
import HomogeneousGroupDetailScreen from './Screens/HomogeneousGroupDetailScreen'
import { client } from './index'
import { ThemeProvider } from '@material-ui/styles'
import { responsiveFontSizes } from '@material-ui/core/styles'
import LoginScreen from './Screens/LoginScreen'
import { useLoginStore } from './globalState'
import Confirm from './Components/Customized/Confirm'
import BackButton from './Components/Customized/BackButton'
import UserAccountButton from './Components/Customized/UserAccountButton'
import PandemicStatusScreen from './Screens/PandemicStatusScreen'


const _theme = createMuiTheme({
    typography: {
        fontSize: 14,
        button: {
            fontSize: '1rem'
        },
        h1: {
            fontSize: 30
        },
        h2: {
            fontSize: 26
        },
        h3: {
            fontSize: 24
        },
        h4: {
            fontSize: 22
        },
        h5: {
            fontSize: 20
        },
        h6: {
            fontSize: 18
        }
    },
    palette: {
        background: {
            default: '#f6f6f6'
        }
    },
    '@global': {
        body: {
            backgroundImage: 'url(https://www.transparenttextures.com/patterns/shattered.png)'
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "center center",
            // backgroundSize: "cover",
            // backgroundAttachment: "fixed",
            // height: "100%"
        }
    }
})

const theme = responsiveFontSizes(_theme)

function PrivateRoute({ children, ...rest }) {
    const isLoggedIn = useLoginStore(state => state.isLoggedIn)
    return (
      <Fragment>{isLoggedIn ? <Route {...rest}>{children}</Route> : <Redirect to={'/login'}/>}</Fragment>
    )
}

function App() {
    return (
      <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
              <CssBaseline/>
              <div className="App" style={{ display: 'flex' }}>
                  <ToastContainer/>
                  <Router>
                      <AppBar position="fixed">
                          <Toolbar>
                              <BackButton/>
                              <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
                                  BHAZIOR
                              </Typography>
                              <UserAccountButton/>
                          </Toolbar>
                      </AppBar>
                      <main style={{ flexGrow: 1 }}>
                          <Toolbar/>
                          <Switch>
                              <Route path={'/login'} exact>
                                  <LoginScreen/>
                              </Route>
                              <PrivateRoute path={'/'} exact>
                                  {/*<OverviewDashboardScreen/>*/}
                                  <ProductionUnitsScreen/>
                              </PrivateRoute>
                              <PrivateRoute path={'/homogeneousGroups/:groupID'} exact>
                                  <HomogeneousGroupDetailScreen/>
                              </PrivateRoute>
                              <PrivateRoute path={'/productionUnits'} exact>
                                  <ProductionUnitsScreen/>
                              </PrivateRoute>
                              <PrivateRoute path={'/productionUnits/:unitID'} exact>
                                  <ProductionUnitDetailScreen/>
                              </PrivateRoute>
                              <PrivateRoute path={'/productionUnits/:unitID/pandemicStatus'} exact>
                                  <PandemicStatusScreen/>
                              </PrivateRoute>
                          </Switch>
                          <Confirm/>
                      </main>
                  </Router>
              </div>
          </ThemeProvider>
      </ApolloProvider>
    )
}

export default App
