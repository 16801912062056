import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import ClassRating from '../ClassRating'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import React from 'react'
import { useTheme } from '@material-ui/styles'

export default function ({ initialClass, initialPercentage, finalClass, finalPercentage }) {
    const theme = useTheme()
    return <Box style={{ flexGrow: 1 }}>
        <Typography style={{ textAlign: 'center' }} variant={'caption'} color={'textSecondary'}>CLASSE DI
            RISCHIO</Typography>
        <Box style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: theme.spacing(3),
            // paddingTop: theme.spacing(4),
            marginRight: theme.spacing(2)
        }}>
            <Box>
                <ClassRating classValue={initialClass} riskPercentage={initialPercentage}/>
                <Typography
                  variant={'caption'}>INIZIALE</Typography>
            </Box>
            <ArrowRightAltIcon fontSize={'large'} style={{ color: '#a8a3a8' }}/>
            <Box>
                <ClassRating classValue={finalClass} riskPercentage={finalPercentage}/>
                <Typography
                  variant={'caption'}>FINALE</Typography>

            </Box>
        </Box>
    </Box>
}