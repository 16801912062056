import React from 'react'
import {
    Menu,
    MenuItem,
    Box
} from '@material-ui/core'
import CrudGrid from '../CrudGrid'
import CardPU from './CardPU'


export default function ProductionUnitsScreen({ unitsList, onUnitClick, onDelete, onEdit }) {
    const [anchorEl, setAnchorEl] = React.useState({ domElement: null, data: null })

    const handleMoreClick = (event, data) => {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl({ domElement: event.currentTarget, data })
    }

    const handleMenuClose = () => {
        setAnchorEl({ domElement: null, data: null })
    }

    const handleDeletePU = () => {
        handleMenuClose()
        anchorEl?.data?.id && onDelete(anchorEl.data)

    }

    const handleEdit = () => {
        handleMenuClose()
        anchorEl?.data?.id && onEdit(anchorEl.data)
    }


    const CardPUWithFunctions = item => {
        return <CardPU handleMoreClick={(e) => handleMoreClick(e, item)} {...item} />
    }

    return <Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl?.domElement}
          keepMounted
          open={Boolean(anchorEl?.domElement)}
          onClose={handleMenuClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem style={{ color: 'red' }} onClick={handleDeletePU}>Delete</MenuItem>
        </Menu>

        <CrudGrid items={unitsList} onItemClick={onUnitClick} renderItem={CardPUWithFunctions}/>
    </Box>
}