import { Card, CardActions, CardHeader, CardMedia, Grid, IconButton, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import styled from 'styled-components'
import RiskDisplayPU from './RiskDisplayPU'
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded'
import PhotoSizeSelectSmallRoundedIcon from '@material-ui/icons/PhotoSizeSelectSmallRounded'
import GroupWorkRoundedIcon from '@material-ui/icons/GroupWorkRounded'
import Box from '@material-ui/core/Box'
import { formatDecimal } from '../../utils'
import ClassRating from '../ClassRating'
import Paper from '@material-ui/core/Paper'
import RiskClass from '../Customized/RiskClass'
import InitialClassVSFinalClass from './InitialClassVSFinalClass'
import Tooltip from '@material-ui/core/Tooltip'

const useStyle = makeStyles(theme => ({
    card: {
        width: 380
    },
    inline: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    marginRight: {
        marginRight: theme.spacing(1)
    }
}))


const StyledCardHeader = styled(({ ...other }) => <CardHeader {...other} />)`
  & .MuiCardHeader-content {
    flex: 1 1 auto;
    width: 100%;
    height: 3.6em;
    line-height: 1.6em;
  }
`;


export default function CardPU({ name, address, image, riskClass, handleMoreClick, k1, k3, homogeneousGroups, risk, employeesCount, ...rest }) {
    const classes = useStyle(rest)
    const theme = useTheme()

    //TODO move to backend side
    const inputedEmployees = homogeneousGroups.reduce((sum, current) => sum += current?.employeesCount, 0)

    return <Card className={classes.card}>
        <StyledCardHeader          
          action={<IconButton aria-label="settings" onClick={handleMoreClick}>
              <MoreVertIcon/>
          </IconButton>}
          title={name}
          subheader={address}
        >
        </StyledCardHeader>
        {/*<CardMedia*/}
        {/*  component={'img'}*/}
        {/*  image={image}*/}
        {/*  style={{ height: 80, objectFit: 'contain' }}*/}
        {/*/>*/}
        <Grid container spacing={1} style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1), marginTop: theme.spacing(2)}} xs={12}>
            <Grid item xs={12}><Typography variant={'caption'} color={'textSecondary'}>COEFFICIENTI</Typography></Grid>
            <Grid item xs={4}><Tooltip
              title={'Coeff. da rischio dei gruppi omogenei e relative attività di mitigazione'}>
                <Box className={classes.inline}><GroupWorkRoundedIcon
                  className={classes.marginRight}/><Typography
                  variant={'caption'}>RISK = {formatDecimal(risk?.final) || 'N/A'}</Typography></Box>
            </Tooltip>
            </Grid>
            <Grid item xs={4}><Tooltip title={'Coeff. da codice ATECO'}>
                <Box className={classes.inline}><BusinessRoundedIcon
                  className={classes.marginRight}/><Typography
                  variant={'caption'}>ATECO = {formatDecimal(k1) || 'N/A'}</Typography></Box>
            </Tooltip></Grid>
            <Grid item xs={4}><Tooltip title={'Coeff. da numerosità aziendale'}><Box
              className={classes.inline}><PhotoSizeSelectSmallRoundedIcon
              className={classes.marginRight}/><Typography
              variant={'caption'}>SIZE = {formatDecimal(k3) || 'N/A'}</Typography></Box></Tooltip></Grid>
        </Grid>
        <Grid container spacing={1} xs={12} style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Grid item xs={8} style={{paddingLeft: theme.spacing(3)}}>
                <Paper elevation={0} style={{backgroundColor: '#f6f6f6'}} className={'triangle-isosceles'}>
                    <RiskClass baseRisk={risk?.raw} initialRisk={risk?.initial}/>
                </Paper>
            </Grid>

            <Grid item xs={4}>
                <RiskDisplayPU
                  employeesCount={employeesCount}
                  inputedEmployees={inputedEmployees}
                  groups={homogeneousGroups}
                />
            </Grid>
        </Grid>


        <Grid container>
            <Grid item xs={12}>
                <InitialClassVSFinalClass
                  initialClass={risk?.initialClass}
                  initialPercentage={risk?.initialPercentage}
                  finalClass={risk?.finalClass}
                  finalPercentage={risk?.finalPercentage}
                />
            </Grid>
        </Grid>


    </Card>
}