import React from 'react'
import { Radar } from 'react-chartjs-2'
import _ from 'lodash'
import { formatDecimal } from '../../utils'

export function countermeasuresToRadarDatatype(homogeneousGroups) {
    return {
        labels: homogeneousGroups.map(c => c.name),
        datasets: [
            {
                data: homogeneousGroups.map(g => formatDecimal(g.risk?.mitigatedRisk)),
                borderColor: 'rgb(46,52,255)',
                label: 'Residuo',
                backgroundColor: 'rgba(34,52,255,0.64)'
            },
            {
                data: homogeneousGroups.map(g => formatDecimal(g.risk?.totalRisk)),
                borderColor: 'rgb(255,153,4)',
                label: 'Inerente',
                backgroundColor: 'rgba(255,175,45,0.64)'
            }]        
    }
}


export default function ({ data }) {
    return <Radar
      data={data ? countermeasuresToRadarDatatype(data) : {}}
      // legend={null}
      options={{
          maintainAspectRatio: false,
          responsive: true,
          scale: {
              ticks: {
                  beginAtZero: true,
                  max: 24,
                  min: 0,
                  stepSize: 6
              },

              pointLabels: {
                  callback: function (label, index, labels) {
                      if (/\s/.test(label)) {
                          return label.split(' ')
                      } else {
                          return label
                      }
                  }
              }
          }
      }}
    />
}