import { gql } from '@apollo/client'

export const HOMOGENEOUS_GROUPS_QUERY = gql`
    query upsertHomogeneousGroup($idList: [ID], $ownerProductionUnit: ID){
        homogeneousGroups(idList: $idList, ownerProductionUnit: $ownerProductionUnit) {
            id,
            name,
            risk {
                mitigatedRisk,
                totalRisk,
                proximityCategories{
                    name
                    activities {
                        aggregation,
                        description,
                        timePercentage,
                        inducedRisk
                        totalRisk
                    }
                }
            },
            ownerProductionUnit {
                id
            },
            employeesCount,
            criticalities
        }
    }
`

export const HOMOGENEOUS_GROUP_DETAILS_QUERY = gql`
    query detailHomogeneousGroup($idList: [ID], $ownerProductionUnit: ID){
        homogeneousGroups(idList: $idList, ownerProductionUnit: $ownerProductionUnit) {
            id,
            name,
            risk {
                proximityCategories {
                    name,
                    weight,
                    activities {
                        aggregation,
                        description,
                        timePercentage,
                        inducedRisk,
                        totalRisk
                    }
                },
                totalRisk,
                mitigatedRisk
            },
            employeesCount,
            criticalities
        }
    }
`


export const INSERT_HOMOGENEOUS_GROUP = gql`
    mutation upsertHomogeneousGroup($id: ID, $name: String!, $employeesCount: Int!, $criticalities: String!, $productionUnitId: ID) {
        insertHomogeneousGroup(homogeneousGroupData: {id: $id, name: $name, employeesCount: $employeesCount, criticalities: $criticalities}, productionUnitId: $productionUnitId) {
            homogeneousGroup {
                id,
                name,
                employeesCount,
                criticalities
            }
        }
    }
`

export const UPDATE_HOMOGENEOUS_GROUP = gql`
    mutation upsertHomogeneousGroup($id: ID, $name: String!, $employeesCount: Int!, $criticalities: String!, $productionUnitId: ID) {
        updateHomogeneousGroup(homogeneousGroupData: {id: $id, name: $name, employeesCount: $employeesCount, criticalities: $criticalities}, productionUnitId: $productionUnitId) {
            homogeneousGroup {
                id,
                name,
                employeesCount,
                criticalities
            }
        }
    }
`

export const UPDATE_HOMOGENEOUS_GROUP_ACTIVITIES = gql`
    mutation updateHGActivities($groupId: ID!, $activities: [ActivityFieldInput]!, $riskGroupIndex: Int!) {
        editGroupRiskActivities(activitiesData: $activities, riskGroupIndex: $riskGroupIndex, groupId: $groupId) {
            group {
                id,
                name,
                risk {
                    proximityCategories {
                        name
                        activities {
                            aggregation
                            description
                            timePercentage
                        }
                    },
                    mitigatedRisk,
                    totalRisk,
                }
            }
        }
    }
`

export const COPY_HOMOGENEOUS_GROUP_ACTIVITIES = gql`
    mutation copyHGActivities($groupId: ID!, $riskObject: RiskFieldInput) {
        copyGroupRiskActivities(groupId: $groupId, riskObject: $riskObject) {
            group {
                id,
                name,
                risk {
                    proximityCategories {
                        name
                        activities {
                            aggregation
                            description
                            timePercentage
                        }
                    },
                    mitigatedRisk,
                    totalRisk,
                }
            }
        }
    }
`

export const DELETE_HOMOGENEOUS_GROUP = gql`
    mutation deleteHomogeneousGroup($id: ID) {
        deleteHomogeneousGroup(homogeneousGroupData: {id: $id}) {
            deletedId
        }
    }
`