import React, { Fragment } from 'react'
import { Container, Box, Grid } from '@material-ui/core'
import DetailPU from '../Components/ProductionUnit/DetailPU'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, gql } from '@apollo/client'
import {
    PRODUCTION_UNITS_QUERY,
    UPDATE_PRODUCTION_UNIT_MITIGATION_ACTIVITIES
} from '../Components/ProductionUnit/GraphQL_queries'
import CircularProgress from '@material-ui/core/CircularProgress'

import { defaultMutationUpdateCallback, notifyFailure, notifySuccess } from '../utils'
import PageTitle from '../Components/Customized/PageTitle'

const updateCacheAfterMutation = (cache, { data }) => defaultMutationUpdateCallback({
    cache,
    mutatedData: data.editProductionUnitMitigationActivities,
    modelName: 'productionUnit',
    modelCollectionName: 'productionUnits',
    gqlFragment: gql`
        fragment ProductionUnit on ProductionUnitType {
            id
            mitigation {
                mitigationActivities {
                    name
                    practicalEfficacy
                    practicalExecution
                    theoreticalMax
                    theoreticalMin
                    k
                }
                totalMitigation
            }
        }
    `
})


export default function ProductionIUnitDetailScreen() {
    const { unitID } = useParams()
    const { loading, data } = useQuery(PRODUCTION_UNITS_QUERY, { variables: { unitId: unitID } })
    const [_updateProductionUnitRemediationActivities] = useMutation(UPDATE_PRODUCTION_UNIT_MITIGATION_ACTIVITIES, {
        update: updateCacheAfterMutation
    })

    function updateProductionUnitRemediationActivities({ mitigationActivities, productionUnitId }) {
        _updateProductionUnitRemediationActivities({
            variables: {
                mitigationActivitiesData: mitigationActivities,
                productionUnitId
            }
        }).then(notifySuccess)
          .catch(notifyFailure)
    }


    return <Container>
        {loading ?
          <CircularProgress/>
          :
          <Box>
              <PageTitle>
                  {data.productionUnits?.[0]?.name}
              </PageTitle>              
              <DetailPU
                data={data.productionUnits?.[0]}
                updateHomogeneousRemediationActivities={updateProductionUnitRemediationActivities}
              />
          </Box>
        }
    </Container>
}