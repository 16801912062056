import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'


export default function CrudGrid({ items, onItemClick, renderItem }) {
    return <Box>
        <Grid container spacing={3} alignContent={'stretch'} justify={'flex-start'}>
            {items.map(item =>
              <Grid item key={item.id}>
                  <Box onClick={() => onItemClick(item)} style={{ cursor: 'pointer' }}>
                      {renderItem({ ...item })}
                  </Box>
              </Grid>
            )}
            {items.length < 1 && <Grid item xs={12}><Typography variant={'subtitle2'} style={{textAlign: 'center'}}>Non ci sono elementi</Typography></Grid>}
        </Grid>
    </Box>
}