import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, TextField, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ActivityRiskNumber from '../ProductionUnit/ActivityRiskNumber'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { AddButton } from '../Customized'
import ColoredRibbon from '../ColoredRibbon'

const useStyle = makeStyles(theme => ({
    form: {
        padding: theme.spacing(2)
        // alignItems: 'center',
        // justifyContent: 'center',
        // display: 'flex'
    },
    formButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formLongField: {
        margin: theme.spacing(1),
        flexGrow: 1
        // minWidth: '20vw'
    },
    formField: {
        margin: theme.spacing(1),
        minWidth: 80
    },
    formSelectField: {
        minWidth: 140
    },
    fieldBox: {
        position: 'relative',
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fieldError: {
        position: 'absolute',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        color: 'red',
        fontSize: theme.typography.fontSize * 0.8
    }
}))

const OutlinedTextField = props => <TextField margin='dense' fullWidth variant={'outlined'} {...props}/>
const OutlinedFloatField = props => <TextField {...props} margin='dense' type={'number'}
                                               inputProps={{ min: '0', max: '100', step: '0.1' }} variant={'outlined'}/>
const OutlinedSelect = ({ label, passClassName, ...props }) => <FormControl margin='dense' variant={'outlined'}
                                                                            className={passClassName}>
    <InputLabel id={`${props.name}-label`}>{label}</InputLabel>
    <Select label={label} labelId={`${props.name}-label`} {...props}/>
</FormControl>


export default function ActivitiesForm({ activities, onSubmit, riskGroupIndex, ...rest }) {
    const name = 'activities'

    const { register, control, handleSubmit, formState, reset, getValues } = useForm({
        mode: 'onChange',
        defaultValues: { [name]: activities }
    })

    const { isSubmitting, isDirty } = formState

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: name
    })
    const classes = useStyle(rest)

    const [customActivities, setCustomActivities] = React.useState(activities);

    React.useEffect(() => { setCustomActivities(activities) }, [activities]);

    function submit(values, event) {
        onSubmit({ activities: values.activities, riskGroupIndex })
        reset(getValues())
    }


    return <form onSubmit={handleSubmit(submit)} className={classes.form}>
        {fields.map((item, index) => (
          <Box key={item.id} className={classes.fieldBox}>
              <OutlinedTextField
                className={classes.formLongField}
                label="descrizione"
                name={`${name}[${index}].description`}
                inputRef={register()}
                defaultValue={item.description} // make sure to set up defaultValue
              />
              <OutlinedFloatField
                className={classes.formField}
                label="tempo %"
                name={`${name}[${index}].timePercentage`}
                inputRef={register()}
                defaultValue={item.timePercentage} // make sure to set up defaultValue
              />
              <Controller
                control={control}
                name={`${name}[${index}].aggregation`}
                defaultValue={item.aggregation || 1.0}
                as={<OutlinedSelect key={'terzi'} passClassName={classes.formSelectField} label="aggregaz. con terzi">
                    <MenuItem value={1.0}>Limitata o nulla</MenuItem>
                    <MenuItem value={1.15}>Senza aggregazioni</MenuItem>
                    <MenuItem value={1.3}>Intrinseche, ma controllabili con procedure</MenuItem>
                    <MenuItem value={1.5}>Insinseche e scarsamente controllabili</MenuItem>
                </OutlinedSelect>}
              />

              {index < fields.length && !!item && <ActivityRiskNumber
                aggregation={customActivities[index].aggregation}
                inducedRisk={customActivities[index].inducedRisk}
                //totalRisk={item.totalRisk}
              />}
                {index < fields.length && <Button onClick={() => { remove(index); setCustomActivities(customActivities.filter((o, i) => i !== index)) }}>
                  <DeleteIcon/>
              </Button>}
          </Box>

        ))}


        <Button key={'add'} style={{ alignSelf: 'flex-end', margin: 4 }} variant={'outlined'}
                color={'primary'}
                disabled={isSubmitting}
            onClick={() => {
                const apObject = { aggregation: 1.0, timePercent: 0, description: '' }
                append(apObject);
                setCustomActivities([...customActivities, apObject])
            }}
        >
            Aggiungi
        </Button>
        <Button key={'submit'} style={{ alignSelf: 'flex-end' }} variant={isDirty ? 'contained' : 'outlined'}
                color={'primary'}
                type="submit" disabled={!isDirty || isSubmitting}>
            Salva
        </Button>
    </form>


}