import React from 'react'
import ProductionUnits from '../Components/ProductionUnit/ListPU'
import { Container, DialogTitle, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { CustomDialog } from '../Components/Customized'

import { useMutation, useQuery } from '@apollo/client'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ProductionUnitEditForm from '../Components/ProductionUnit/ProductionUnitEditForm'
import { makeStyles } from '@material-ui/core/styles'
import {
    DELETE_PRODUCTION_UNIT,
    PRODUCTION_UNITS_QUERY,
    INSERT_PRODUCTION_UNIT, UPDATE_PRODUCTION_UNIT
} from '../Components/ProductionUnit/GraphQL_queries'
import { notifyFailure } from '../utils'
import { useConfirmDialog } from '../globalState'
import PageTitle from '../Components/Customized/PageTitle'


const useStyle = makeStyles(theme => ({
    fab: {
        position: 'absolute',
        right: theme.spacing(4),
        bottom: theme.spacing(4)
    }
}))

export default function ProductionUnitsScreen(props) {
    const classes = useStyle(props)
    const { loading, data } = useQuery(PRODUCTION_UNITS_QUERY)
    const [_deleteProductionUnit] = useMutation(DELETE_PRODUCTION_UNIT, { refetchQueries: [{ query: PRODUCTION_UNITS_QUERY }] })
    const [updateProductionUnit] = useMutation(UPDATE_PRODUCTION_UNIT)
    const [insertProductionUnit] = useMutation(INSERT_PRODUCTION_UNIT, { refetchQueries: [{ query: PRODUCTION_UNITS_QUERY }] })
    const [isEditFormOpen, setIsEditFormOpen] = React.useState(false)
    const [selectedUnit, setSelectedUnit] = React.useState(null)
    const appHistory = useHistory()
    const [openConfirm] = useConfirmDialog(state => [state.openConfirm])


    function handleUnitClick(unit) {
        appHistory.push(`/productionUnits/${unit.id}`)
    }

    function handleEditClick(unit) {
        setSelectedUnit(unit)
        setIsEditFormOpen(true)
    }

    function handleFormSubmit(productionUnitData) {

        const mutationToUse = productionUnitData.id ? updateProductionUnit : insertProductionUnit
        mutationToUse({
            variables: productionUnitData
        }).then(() => {
            setIsEditFormOpen(false)
            return Promise.resolve()
        }).catch(notifyFailure)
    }

    function deleteProductionUnit({ id }) {
        openConfirm({
            title: 'Cancellare unità produttiva?',
            description: 'Questa azione è irreversibile. I gruppi omogenei al suo interno verranno rimossi definitivamente. Ciò non influenzerà le altre unità produttive: i gruppi omogenei sono replicati',
            onConfirm: () => _deleteProductionUnit({ variables: { id } }).catch(notifyFailure)
        })

    }

    function handleCreateIntent() {
        setSelectedUnit(null)
        setIsEditFormOpen(true)
    }

    return <Container>
        <PageTitle>
            Unità produttive
        </PageTitle>
        {!!loading && <p>Loading...</p>}
        {!!data?.productionUnits && <ProductionUnits
          unitsList={data.productionUnits}
          onUnitClick={handleUnitClick}
          onDelete={deleteProductionUnit}
          onEdit={handleEditClick}
        />}

        <CustomDialog
          onClose={() => setIsEditFormOpen(false)}
          open={isEditFormOpen}
          aria-labelledby="simple-dialog-title"
        >
            <DialogTitle id="simple-dialog-title">Production Unit</DialogTitle>
            <ProductionUnitEditForm key={selectedUnit?.id} onSubmit={handleFormSubmit} unitData={selectedUnit}/>
        </CustomDialog>
        <Fab className={classes.fab} color="primary" aria-label="add"
             onClick={handleCreateIntent}>
            <AddIcon/>
        </Fab>
    </Container>
}