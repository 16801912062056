import React, { Fragment } from 'react'
import { Box, Menu, MenuItem } from '@material-ui/core'
import CrudGrid from '../CrudGrid'
import CardHG from './CardHG'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles(theme => ({
    card: {
        width: 250
    }
}))


export default function ListHG({ list = [], onItemClick, onDelete, onEdit, ...rest }) {
    const classes = useStyle(rest)
    const [anchorEl, setAnchorEl] = React.useState({ domElement: null, data: null })

    const handleMoreClick = (event, data) => {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl({ domElement: event.currentTarget, data })
    }
    const HGCardWIthFunctions = item => {
        return <CardHG className={classes.card} handleMoreClick={(e) => handleMoreClick(e, item)} {...item} />
    }

    const handleMenuClose = () => {
        setAnchorEl({ domElement: null, data: null })
    }

    const handleDelete = () => {
        handleMenuClose()
        anchorEl?.data?.id && onDelete(anchorEl.data)

    }

    const handleEdit = () => {
        handleMenuClose()
        anchorEl?.data?.id && onEdit(anchorEl.data)
    }

    return <Fragment>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl?.domElement}
          keepMounted
          open={Boolean(anchorEl?.domElement)}
          onClose={handleMenuClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem style={{ color: 'red' }} onClick={handleDelete}>Delete</MenuItem>
        </Menu>
        <CrudGrid items={list} onItemClick={onItemClick} renderItem={HGCardWIthFunctions}/>
    </Fragment>

}