import React from 'react'
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Controller, useForm } from 'react-hook-form'
import provinces from '../../province-italia.json'


function ATECOInputField({ defaultValue, value, callback, ...props }) {
    const [localValue, setLocalValue] = React.useState(defaultValue || '')

    function restrictInput(e) {
        const value = e.target.value.toUpperCase()
        const regex = new RegExp(/^[A-Z]{1,2}((\.(\d+)){1,5})?(\.)?$/)
        if (value === '' || value.match(regex)) {
            setLocalValue(value)
            callback && callback(e.target.value)
        }
    }

    return <OutlinedTextField {...props} value={localValue} onChange={restrictInput} />
}

const OutlinedTextField = props => <TextField variant={'outlined'} {...props} />

const OutlinedSelect = ({ label, passClassName, ...props }) =>
    <FormControl margin='dense' variant={'outlined'} className={passClassName}>
    <InputLabel id={`${props.name}-label`}>{label}</InputLabel>
    <Select label={label} labelId={`${props.name}-label`} {...props} />
</FormControl>

const useStyle = makeStyles(theme => ({
    form: {
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    formField: {
        margin: theme.spacing(1)
    },
    formSelectField: {
        minWidth: 140,
    },
    menuItem: {
        "&:hover": {
            backgroundColor: '#dedede'
        }
    }
}))

export default function ProductionUnitEditForm({ unitData, onSubmit, ...rest }) {
    const { register, handleSubmit, formState, control } = useForm()
    const { isSubmitting, isDirty } = formState
    const unit = unitData || {}


    const classes = useStyle(rest)

    function submitWithId(values) {
        onSubmit({ id: unitData?.id, ...values })
    }

    return <form onSubmit={handleSubmit(submitWithId)} className={classes.form}>
        <Box>
            <Box>
                <OutlinedTextField defaultValue={unit.name} className={classes.formField}
                    inputRef={register({ required: true })} name={'name'}
                    label={'name'} />
                <Controller
                    control={control}
                    name="industryCode"
                    render={({ onChange, onBlur, value, name }) => (

                        <ATECOInputField callback={onChange} defaultValue={unit.industryCode} className={classes.formField}
                            inputRef={register({ required: true })}
                            name={name} label={'Codice ATECO'} />
                    )}
                />


                <OutlinedTextField defaultValue={unit.employeesCount} className={classes.formField}
                    inputRef={register({ required: true })}
                    name={'employeesCount'} type={'number'} label={'Numerosità'} />
            </Box>
            <Box>

                <OutlinedTextField defaultValue={unit.address} className={classes.formField}
                    inputRef={register({ required: true })}
                    name={'address'} label={'Indirizzo'} />

                <Controller
                    control={control}
                    name={'province'}
                    defaultValue={unit.province || 'none'}
                    as={
                        <OutlinedSelect key={'province'} passClassName={classes.formSelectField} className={classes.formField} label="Provincia">
                            {provinces.map((o, i) => 
                                <MenuItem key={i}
                                    value={o.sigla} className={classes.menuItem}>{o.nome}</MenuItem>)}
                        </OutlinedSelect>}
                />
            </Box>
        </Box>

        <Box>
            <Button style={{ alignSelf: 'flex-end' }} variant={isDirty ? 'contained' : 'outlined'} color={'primary'}
                type="submit" disabled={isSubmitting}>
                Submit
            </Button>
        </Box>
    </form>

    // return <Formik
    //   enableReinitialize={true}
    //   initialValues={unitData || {}}
    //   onSubmit={(values, { setSubmitting }) => {
    //       onSubmit(values).then(setSubmitting(false))
    //   }}
    // >
    //     {({ isSubmitting, touched }) => (
    //       <Form className={classes.form}>
    //           <Box>
    //               <Field type="input" label="name" name="name" as={OutlinedTextField}
    //                      className={classes.formField}/>
    //               <Field type="input" label="industryCode" name="industryCode" as={OutlinedTextField}
    //                      className={classes.formField}/>
    //               <Field type="number" label="Employees count" name="employeesCount" as={OutlinedTextField}
    //                      className={classes.formField}/>
    //               {/*<ErrorMessage name="password" component="div"/>*/}
    //           </Box>
    //           <Box>
    //               <Button style={{ alignSelf: 'flex-end' }} color={_.isEmpty(touched) ? 'contained' : 'outlined'}
    //                       type="submit" disabled={isSubmitting}>
    //                   Submit
    //               </Button>
    //           </Box>
    //       </Form>
    //     )}
    // </Formik>
}