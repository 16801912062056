import React from 'react'
import { Box, Typography, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useForm, useFieldArray } from 'react-hook-form'
import i18n from '../../i18n';


const useStyle = makeStyles(theme => ({
      form: {
          padding: theme.spacing(2)
          // alignItems: 'center',
          // justifyContent: 'center',
          // display: 'flex'
      },
      formField: {
          margin: theme.spacing(1)
      },
      marginRight: {
          marginRight: theme.spacing(1)
      },
      fieldBox: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
      }
  })
)

const OutlinedNumberField = props => <TextField {...props} margin='dense' type={'number'}
                                                style={{ width: 100 }}
                                                inputProps={{
                                                    min: '0',
                                                    max: '100',
                                                    step: '1'
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                }}
                                                variant={'outlined'}/>


export default function MitigationForm({ onSubmit, mitigationActivities = [], ...rest }) {

    const name = 'mitigationActivities'

    const { register, control, handleSubmit, formState } = useForm({
        mode: 'onChange',
        defaultValues: { [name]: mitigationActivities }
    })

    const { isSubmitting, isDirty } = formState

    const {
        fields
        // append, prepend, remove, swap, move, insert 
    } = useFieldArray({
        control,
        name: name
    })
    const classes = useStyle(rest)

    function submit(values, event) {
        onSubmit({
            mitigationActivities: values.mitigationActivities.map(a => ({
                ...a,
                practicalExecution: parseInt(a.practicalExecution) || null,
                practicalEfficacy: parseInt(a.practicalEfficacy) || null
            }))
        })
    }

    return <form onSubmit={handleSubmit(submit)} className={classes.form}>
        {fields.map((item, index) => (
          <Box key={item.id} className={classes.fieldBox}>
                <Typography title={i18n.t(`${item.name}_tips`)} variant={'body2'}>{i18n.t(item.name)}</Typography>
              <Box>
                  <OutlinedNumberField
                    className={classes.marginRight}
                    title={'Attuazione'}
                    label="Attuaz."
                    name={`${name}[${index}].practicalExecution`}
                    inputRef={register()}
                    defaultValue={item.practicalExecution} // make sure to set up defaultValue
                  />
                    <OutlinedNumberField
                    title="Efficacia"
                    label="Efficacia"
                    name={`${name}[${index}].practicalEfficacy`}
                    inputRef={register()}
                    defaultValue={item.practicalEfficacy} // make sure to set up defaultValue
                  />
              </Box>
              {/*<button type="button" onClick={() => remove(index)}>Delete</button>*/}
          </Box>
        ))}
        <Button style={{ alignSelf: 'flex-end' }} variant={isDirty ? 'contained' : 'outlined'}
                color={'primary'}
                type="submit" disabled={!isDirty || isSubmitting}>
            Salva
        </Button>
    </form>
    //
    // return <Formik
    //   enableReinitialize
    //   initialValues={{ mitigationActivities }}
    //   onSubmit={(values, { setSubmitting }) => {
    //       onSubmit({ mitigationActivities: values.mitigationActivities }).then(setSubmitting(false))
    //   }}>
    //     {({ isSubmitting, values, touched }) => (
    //       <Form className={classes.form}>
    //           <FieldArray name={'mitigationActivities'}
    //                       render={arrayHelpers =>
    //                         <Box>
    //                             {values.mitigationActivities.map((activity, index) => <Box className={classes.fieldBox}>
    //                                   <Typography variant={'h7'}>{activity.name}</Typography>
    //                                   <Box>
    //                                       <Field
    //                                         className={classes.formField}
    //                                         type="input"
    //                                         value={mitigationActivities[index].practicalExecution * 100}
    //                                         onChange={e => e.target.value / 100}
    //                                         label="Attuazione"
    //                                         name={`mitigationActivities.${index}.practicalExecution`}
    //                                         as={OutlinedFloatField}
    //                                       />
    //                                       <FastField
    //                                         type="input"
    //                                         label="Efficacia"
    //                                         name={`mitigationActivities.${index}.practicalEfficacy`}
    //                                         as={OutlinedFloatField}
    //                                         className={classes.formField}/>
    //                                   </Box>
    //                               </Box>
    //                             )}
    //                             <Button className={classes.formButton} primary
    //                                     onClick={() => arrayHelpers.insert(values.activities.length, {})}>
    //                                 <AddIcon/>
    //                             </Button>
    //                         </Box>}>
    //
    //           </FieldArray>
    //           <Button style={{ alignSelf: 'flex-end' }} variant={!_.isEmpty(touched) ? 'contained' : 'outlined'}
    //                   color={'primary'}
    //                   type="submit" disabled={_.isEmpty(touched) || isSubmitting}>
    //               Salva
    //           </Button>
    //       </Form>
    //     )}
    // </Formik>
}