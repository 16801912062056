import React from 'react'
import { Paper, Box } from '@material-ui/core'
import { HorizontalBar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import { formatDecimal, percentageToGreenRedRange } from '../../utils'
import Typography from '@material-ui/core/Typography'


const useStyle = makeStyles(theme => ({
    chartBox: {
        maxHeight: 70,
        maxWidth: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto'
    }
}))

export default function RiskComparisonGraph({ style, baseRisk = 0, reducedRisk = 0, ...rest }) {
    const max = 24
    const classes = useStyle(rest)
    const percentBaseRisk = baseRisk / max * 100
    const percentReducedRisk = reducedRisk / max * 100
    const colors = {
        full1: percentageToGreenRedRange(percentReducedRisk, 100, 90),
        full2: percentageToGreenRedRange(percentBaseRisk, 100, 90, 0.8),
        base1: percentageToGreenRedRange(percentReducedRisk, undefined, 100),
        base2: percentageToGreenRedRange(percentBaseRisk, 45, 80, 0.35),
        hover1: percentageToGreenRedRange(percentReducedRisk, 90, 100),
        hover2: percentageToGreenRedRange(percentBaseRisk, 80, 100, 0.7)
    }
    return <Box className={classes.chartBox}>
        <Typography variant='caption'>RISCHIO</Typography>
        <HorizontalBar
          data={{
              labels: ['Rischio residuo', 'Rischio inerente'],
              datasets: [{
                  data: [formatDecimal(reducedRisk), formatDecimal(baseRisk)],
                  borderWidth: 1,
                  borderColor: [colors.full1, colors.full2],
                  hoverBorderColor: [colors.full1, colors.full2],
                  backgroundColor: [colors.base1, colors.base2],
                  hoverBackgroundColor: [colors.hover1, colors.hover2]
              }]
          }}
          options={{
              legend: null,
              responsive: true,
              animationDuration: 0,
              maintainAspectRatio: false,
              scales: {
                  xAxes: [{
                      stacked: true,
                      display: true,
                      ticks: {
                          display: false,
                          max: max,
                          min: 0,
                          stepSize: max / 2
                      },
                      barPercentage: 0.4
                  }]
              }
          }}
        />
    </Box>
}