import React from 'react'
import { Box, Paper, DialogTitle, Grid, Typography } from '@material-ui/core'
import ListHG from '../Components/HomogeneousGroup/ListHG'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { AddButton, CustomDialog } from '../Components/Customized'
import HomogeneousGroupEditForm from '../Components/HomogeneousGroup/HomogeneousGroupEditForm'
import { gql, useMutation, useQuery } from '@apollo/client'
import { defaultMutationUpdateCallback, notifyFailure } from '../utils'
import {
    DELETE_HOMOGENEOUS_GROUP,
    HOMOGENEOUS_GROUPS_QUERY, INSERT_HOMOGENEOUS_GROUP, UPDATE_HOMOGENEOUS_GROUP,
    UPSERT_HOMOGENEOUS_GROUP
} from '../Components/HomogeneousGroup/GraphQL_queries'
import Button from '@material-ui/core/Button'
import { useConfirmDialog } from '../globalState'
import HGRadarGraphSummary from '../Components/HomogeneousGroup/HGRadarGraphSummary'
import { useTheme } from '@material-ui/styles'


const useStyle = makeStyles(theme => ({
    fab: {
        position: 'absolute',
        right: theme.spacing(4),
        bottom: theme.spacing(4)
    }
}))


const updateCacheAfterInsertion = (cache, { data }) => console.log({ data }) || defaultMutationUpdateCallback({
    cache,
    mutatedData: data.insertHomogeneousGroup,
    modelName: 'homogeneousGroup',
    modelCollectionName: 'homogeneousGroups',
    gqlFragment: gql`
        fragment NewGroup on HomogeneousGroupType {
            id,
            name,
            ownerProductionUnit {
                id
            }
        }
    `
})

const updateCacheAfterDeletion = (cache, { data }) => console.log({ data }) || defaultMutationUpdateCallback({
    cache,
    mutatedData: data.deleteHomogeneousGroup,
    modelName: 'homogeneousGroup',
    modelCollectionName: 'homogeneousGroups',
    isDelete: true
})


export default function HomogeneousGroupsSection({ productionUnitId, groupsIDs, ...rest }) {
    const classes = useStyle(rest)
    const theme = useTheme()
    const { loading, error, data, refetch } = useQuery(HOMOGENEOUS_GROUPS_QUERY, { variables: { ownerProductionUnit: productionUnitId } })
    const [_deleteHomogeneousGroup] = useMutation(DELETE_HOMOGENEOUS_GROUP, { update: updateCacheAfterDeletion })
    const [insertHomogeneousGroup] = useMutation(INSERT_HOMOGENEOUS_GROUP)
    const [updateHomogeneousGroup] = useMutation(UPDATE_HOMOGENEOUS_GROUP)
    const appHistory = useHistory()
    const [isEditFormOpen, setIsEditFormOpen] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState(null)
    const [openConfirm] = useConfirmDialog(state => [state.openConfirm])


    async function handleFormSubmit(homogeneousGroupData) {
        const mutation = homogeneousGroupData.id ? updateHomogeneousGroup : insertHomogeneousGroup
        try {
            await mutation({
                variables: {
                    ...homogeneousGroupData,
                    productionUnitId: productionUnitId
                }
            })
            refetch()
            setIsEditFormOpen(false)
        } catch (e) {
            notifyFailure(e)
        }
        return true
    }

    function deleteHomogeneousGroup({ id }) {
        openConfirm({
            title: 'Cancella gruppo omogeneo',
            description: 'Questa azione è irreversibile',
            onConfirm: () => _deleteHomogeneousGroup({ variables: { id } }).catch(notifyFailure)
        })

    }


    function openEditForm(unit) {
        setSelectedItem(unit)
        setIsEditFormOpen(true)
    }


    function handleCreateIntent() {
        setSelectedItem(null)
        setIsEditFormOpen(true)
    }

    return <Box>
        <Grid container spacing={3}>
            <Grid item xs={8} sm={10} md={9}>
                <Typography variant="h4">
                    Gruppi omogenei
                </Typography>
            </Grid>
            <Grid item xs={4} sm={2} md={1}>
                <AddButton onClick={handleCreateIntent}/>
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3} style={{ maxHeight: 366 }}>
                <Paper elevation={0} style={{ height: '100%' }}>
                    <HGRadarGraphSummary data={data?.homogeneousGroups}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
                <ListHG
                  list={data?.homogeneousGroups}
                  onCreate={handleCreateIntent}
                  onDelete={deleteHomogeneousGroup}
                  onEdit={openEditForm}
                  onItemClick={unit => appHistory.push(`/homogeneousGroups/${unit.id}`)}
                />
            </Grid>
        </Grid>
        <CustomDialog
          onClose={() => setIsEditFormOpen(false)}
          open={isEditFormOpen}
          aria-labelledby="simple-dialog-title"
        >
            <DialogTitle id="simple-dialog-title">Homogeneous Group</DialogTitle>
            <HomogeneousGroupEditForm
              key={selectedItem?.id}
              onSubmit={handleFormSubmit}
              unitData={selectedItem}
              homogeneousGroups={data?.homogeneousGroups}
            />
        </CustomDialog>
    </Box>
}