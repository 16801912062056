import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import ArrowUpwardRounded from '@material-ui/icons/ArrowUpwardRounded'
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded'
import RemoveRounded from '@material-ui/icons/RemoveRounded'
import DashboardValue from '../DashboardValue'

export default function GrowthIndicator({ label = 'Trend Contagi', trend = -1 }) {

    const Icon = trend === 0 ? RemoveRounded : trend > 0 ? ArrowUpwardRounded : ArrowDownwardRounded
    const color = trend === 0 ? 'rgb(165,165,165)' : trend > 0 ? 'rgb(202,40,40)' : 'rgb(39,194,39)'
    const trendSummary = trend === 0 ? 'e potenziali contagi: invariato' : trend > 0 ? 'e potenziali contagi: in aumento' : 'e potenziali contagi: in diminuzione'

    return <DashboardValue
      title={label}
      subtitle={trendSummary}
      tooltipText={'Fonte dati: Ministero della Salute, Istituto Superiore di Sanità Cabina di Regia ai sensi del DM Salute 30 aprile 2020'}
      trendIndicatorComponent={() => <Icon style={{ color, fontSize: 35 }}/>}
    />
}
