import React, { Fragment } from 'react'
import { useTheme } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import Tooltip from '@material-ui/core/Tooltip'
import { percentageToGreenRedRange } from '../../utils'
import WarningRounded from '@material-ui/icons/WarningRounded'
import IconButton from '@material-ui/core/IconButton'

const GroupDot = ({ color }) => {
    console.log('using color', color)
    const theme = useTheme()
    return <div style={{
        margin: theme.spacing(0.25),
        width: 24,
        height: 24,
        borderRadius: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color || '#666666'
    }}>
        <GroupRoundedIcon style={{ fontSize: 15 }}/>
    </div>
}

function colorFor(value, max) {
    console.log({ colr: value })
    if (value === null || value === undefined) return '#c2c2c2'
    return percentageToGreenRedRange(value / max * 100, 50, 95)
}

export default function HGStatusDots({ groups }) {

    const theme = useTheme()
    const appHistory = useHistory()
    function calcTitle(group, hascriticalities) {
        let name = group.name
        if (group?.risk?.mitigatedRisk === null || group?.risk?.mitigatedRisk === undefined) {
            name += ' - dati insufficienti'
        }
        if (hascriticalities) {
            name += ' - criticità presenti'
        }
        return name
    }
    
    function handleGroupClick(event, groupId) {
        // event.preventDefault()
        event.stopPropagation()
        appHistory.push(`/homogeneousGroups/${groupId}`)
    }

    return <Fragment>
        {groups.map(group => {
              const groupHasCriticalities = group?.criticalities && group?.criticalities !== 'none'
              return <span style={{ position: 'relative' }} key={group.id}>
            <Tooltip title={calcTitle(group, groupHasCriticalities)}>
            <IconButton size={'small'} onClick={(e) => handleGroupClick(e, group.id)}>
                <GroupDot
                  color={colorFor(group?.risk?.mitigatedRisk, 24)}/>
            </IconButton>
            
        </Tooltip>
                  {groupHasCriticalities && <WarningRounded
                    style={{
                        position: 'absolute',
                        bottom: 1,
                        left: 1,
                        fontSize: 13,
                        color: theme.palette.warning.dark
                    }}/>}
          </span>
          }
        )}
    </Fragment>
}