import React from 'react'
import { Box, Button, MenuItem, TextField, InputLabel, FormControl, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useLazyQuery } from '@apollo/client'
import Tooltip from '@material-ui/core/Tooltip'


import { COPY_HOMOGENEOUS_GROUP_ACTIVITIES, HOMOGENEOUS_GROUP_DETAILS_QUERY } from './GraphQL_queries'

const OutlinedTextField = props => <TextField variant={'outlined'} {...props} />

const useStyle = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2)
    },
    form: {
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    formField: {
        margin: theme.spacing(1)
    },
    formSelectField: {
        minWidth: 140
    },
    menuItem: {
        '&:hover': {
            backgroundColor: '#dedede'
        }
    },
    marginLeft: {
      marginLeft: theme.spacing(1)
    }
}))

const OutlinedSelect = ({ label, passClassName, ...props }) => <FormControl margin='dense' variant={'outlined'}
                                                                            className={passClassName}>
    <InputLabel id={`${props.name}-label`}>{label}</InputLabel>
    <Select label={label} labelId={`${props.name}-label`} {...props} />
</FormControl>


export default function HomogeneousGroupEditForm({ unitData, onSubmit, homogeneousGroups, ...rest }) {
    const { register, control, handleSubmit, errors } = useForm()
    const [isSubmitting, setSubmitting] = React.useState(false)
    const classes = useStyle(rest)
    const [hgToCopy, setHgToCopy] = React.useState('none')

    const [copyHGActivities] = useMutation(COPY_HOMOGENEOUS_GROUP_ACTIVITIES)
    const [getHGDetails] = useLazyQuery(HOMOGENEOUS_GROUP_DETAILS_QUERY, {
        onCompleted: (data) => {
            console.log(data.homogeneousGroups[0].risk)
            copyHGActivities({
                variables: {
                    groupId: unitData.id,
                    riskObject: data.homogeneousGroups[0].risk
                }
            })
        }
    })


    function submit(values, event) {
        setSubmitting(true)
        console.log(hgToCopy)
        if (hgToCopy !== 'none') {
            getHGDetails({
                variables: {
                    idList: [hgToCopy]
                }
            })
        }
        onSubmit({ ...unitData, ...values }).then(setSubmitting(false))
    }

    return (
      <Box>
          <form className={classes.form} onSubmit={handleSubmit(submit)}>
              <Box>
                  <OutlinedTextField
                    label={'Nome'}
                    inputRef={register({ required: true })}
                    name={'name'}
                    defaultValue={unitData?.name}
                    className={classes.formField}
                  />
                  {errors.name && <span>This field is required</span>}

                  <OutlinedTextField
                    label={'Numerosità'}
                    inputRef={register({ required: true })}
                    name={'employeesCount'}
                    defaultValue={unitData?.employeesCount}
                    className={classes.formField}
                    type={'number'}
                  />
                  {errors.employeesCount && <span>This field is required</span>}

                  <Controller
                    control={control}
                    name={'criticalities'}
                    defaultValue={unitData?.criticalities || 'none'}
                    as={<OutlinedSelect key={'criticalities'} passClassName={classes.formSelectField}
                                        className={classes.formField} label="Criticità">
                        <MenuItem value={'none'} className={classes.menuItem}>Nessuna</MenuItem>
                        <MenuItem value={'business'} className={classes.menuItem}>Business</MenuItem>
                        <MenuItem value={'service'} className={classes.menuItem}>Servizi essenziali</MenuItem>
                        <MenuItem value={'health'} className={classes.menuItem}>Health</MenuItem>
                    </OutlinedSelect>}
                  />
              </Box>
              <Box>
                  <Button style={{ alignSelf: 'flex-end' }} color={'primary'} type="submit" disabled={isSubmitting}>
                      Submit
                  </Button>
              </Box>
          </form>
          <Box className={classes.container}>
              <OutlinedSelect
                passClassName={classes.formSelectField}
                className={classes.formField}
                label="Importa attività da: "
                onChange={e => setHgToCopy(e.target.value)}
                value={hgToCopy}
                disabled={unitData === null}
              >
                  {homogeneousGroups && homogeneousGroups.map((e, i) => <MenuItem key={i} value={e.id}
                                                                                  className={classes.menuItem}>{e.name}</MenuItem>)}
                  <MenuItem value={'none'} className={classes.menuItem}>Nessuno</MenuItem>
              </OutlinedSelect>
              <div className={classes.marginLeft}>
                  {unitData === null &&
                  <Typography color={'textSecondary'} variant={'caption'}>Potrai importare attività dopo aver creato il
                      gruppo</Typography>}

              </div>
          </Box>

      </Box>
    )
}
